import styled from '@emotion/styled';
import { FormattedNumber } from 'react-intl';

export const Total = ({ investment, currentWorth, loading }) => {
    const sumInvestment = Object.values(investment).reduce((acc, value) => acc + value, 0);
    const sumCurrentWorth = currentWorth.reduce((acc, value) => acc + value, 0);
    const difference = ((sumCurrentWorth - sumInvestment) / sumInvestment) * 100;

    return (
        <Wrapper>
            <Top>
                <Title>Totaal</Title>
            </Top>
            <Middle>
                <Left>
                    <Row>
                        <Description>Inleg</Description>
                        <Value>
                            {loading ? (
                                'Laden...'
                            ) : (
                                <FormattedNumber
                                    value={sumInvestment}
                                    style="currency"
                                    currency="EUR"
                                />
                            )}
                        </Value>
                    </Row>

                    <Row>
                        <Description>Waarde</Description>
                        <Value>
                            {loading ? (
                                'Laden...'
                            ) : (
                                <FormattedNumber
                                    value={sumCurrentWorth}
                                    style="currency"
                                    currency="EUR"
                                />
                            )}
                        </Value>
                    </Row>
                    <Row>
                        <Description>Verschil</Description>
                        <DifferenceValue isPositive={sumCurrentWorth - sumInvestment > 0}>
                            {loading ? (
                                'Laden...'
                            ) : (
                                <FormattedNumber
                                    value={sumCurrentWorth - sumInvestment}
                                    style="currency"
                                    currency="EUR"
                                />
                            )}
                        </DifferenceValue>
                    </Row>
                </Left>
                <Right>
                    <img src="/images/rocket.svg" />
                </Right>
            </Middle>
            <Bottom isPositive={difference > 0}>
                {loading ? (
                    'Laden...'
                ) : (
                    <>
                        {difference > 0 && '+'}
                        {Math.round(difference * 100) / 100}%
                    </>
                )}
            </Bottom>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    border-radius: 10px;
    background: #ffffff;
`;

const Top = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 24px;
`;

const Title = styled.h3`
    font-size: 28px;
    color: rgb(2, 0, 36);
`;

const Middle = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: #333333;
    padding: 18px 24px;
    align-items: center;
    margin-top: -25px;
`;

const Left = styled.div``;

const Right = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    margin-right: -50px;

    img {
        height: 100px;

        @media (min-width: 1024px) {
            height: 150px;
        }
    }
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 12px;
`;

const Description = styled.span`
    text-transform: uppercase;
    font-family: 'Roboto Mono', monospace;
    font-size: 18px;

    @media (min-width: 1024px) {
        font-size: 28px;
    }
`;

const Value = styled.span`
    font-weight: 700;
    font-size: 18px;

    @media (min-width: 1024px) {
        font-size: 28px;
    }
`;

const DifferenceValue = styled.span`
    font-weight: 700;
    font-size: 18px;
    color: ${({ isPositive }) => (isPositive ? '#229554' : '#ee555a')};

    @media (min-width: 1024px) {
        font-size: 28px;
    }
`;

const Bottom = styled.div`
    background: ${({ isPositive }) => (isPositive ? '#229554' : '#ee555a')};
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    color: #ffffff;
`;
