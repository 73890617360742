import styled from '@emotion/styled';
import superagent from 'superagent';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { differenceInDays } from 'date-fns';

import { Coin } from './Coin';
import { Total } from './Total';

const investment = {
    vra: 398.7,
    eqz: 249.35,
    trias: 249.35,
};

const useData = (coin) =>
    useQuery(
        ['coin', coin],
        async () => {
            const { body } = await superagent(
                `https://api.coingecko.com/api/v3/simple/price?ids=${coin}&vs_currencies=eur`,
            );

            return body[coin].eur;
        },
        {
            refetchInterval: 10000,
        },
    );

/*
    Op 25 mei 50688 VRA op Verawallet gezet, 1000 VRA transactiekosten => staking. Rewards vanaf 26-5. https://etherscan.io/tx/0x9cf94e4bddec891a3f7608175da6ed07ba340dc769e7ff44dde8b3b4486862bf

 */
const Home = () => {
    const startingAmount = 49688;
    const days = {
        2021: differenceInDays(new Date('03/01/2022'), new Date('05/26/2021')),
        2022: differenceInDays(new Date(), new Date('03/01/2022')),
        2023: differenceInDays(new Date(), new Date('04/01/2023')),
    };

    const stakingRewards2021 = startingAmount * 0.0007 * days['2021']; //25.55%
    const stakingRewards2022 = stakingRewards2021 * 0.0005 * days['2022']; //18.25%
    const stakingRewards2023 = stakingRewards2022 * 0.00041 * days['2023']; // 15%

    const coinsAmount = {
        vra: Math.floor(
            startingAmount + stakingRewards2021 + stakingRewards2022 + stakingRewards2023,
        ),
        eqz: 290,
        trias: 39.42,
    };

    const { isLoading: verasityLoading, data: verasityRate } = useData('verasity');
    const { isLoading: equalizerLoading, data: equalizerRate } = useData('equalizer');
    const { isLoading: triasLoading, data: triasRate } = useData('trias-token');

    const [currentWorth, setCurrentWorth] = useState([]);
    const [showMartijn, setShowMartijn] = useState(false);

    useEffect(() => {
        setCurrentWorth([
            verasityRate * coinsAmount.vra,
            equalizerRate * coinsAmount.eqz,
            triasRate * coinsAmount.trias,
        ]);
    }, [
        coinsAmount.vra,
        coinsAmount.eqz,
        coinsAmount.trias,
        verasityRate,
        equalizerRate,
        triasRate,
    ]);

    return (
        <Wrapper>
            <MartijnButton onClick={() => setShowMartijn(!showMartijn)}>
                {!showMartijn ? 'Wat doet deze knop?' : 'Oke stop maar'}
            </MartijnButton>
            {showMartijn ? (
                <Martijn>
                    <img src="/images/martijn.jpg" />
                </Martijn>
            ) : (
                <>
                    <Header>
                        <img src="/images/logo.svg" width="350" />
                    </Header>
                    <Main>
                        <Col12>
                            <Total
                                loading={verasityLoading || equalizerLoading || triasLoading}
                                investment={investment}
                                currentWorth={currentWorth}
                            />
                        </Col12>

                        <Col4>
                            <Coin
                                loading={verasityLoading}
                                name="Verasity"
                                investment={investment.vra}
                                amount={coinsAmount.vra}
                                rate={verasityRate}
                                currentWorth={verasityRate * coinsAmount.vra}
                            />
                        </Col4>
                        <Col4>
                            <Coin
                                loading={equalizerLoading}
                                name="Equalizer"
                                investment={investment.eqz}
                                amount={coinsAmount.eqz}
                                rate={equalizerRate}
                                currentWorth={equalizerRate * coinsAmount.eqz}
                            />
                        </Col4>
                        <Col4>
                            <Coin
                                loading={triasLoading}
                                name="Trias"
                                investment={investment.trias}
                                amount={coinsAmount.trias}
                                rate={triasRate}
                                currentWorth={triasRate * coinsAmount.trias}
                            />
                        </Col4>
                    </Main>
                </>
            )}
        </Wrapper>
    );
};

export default Home;

const Wrapper = styled.div`
    max-width: 1024px;
    padding: 0 24px 100px;
    margin: 0 auto;
`;

const Martijn = styled.div`
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes scale {
        from {
            transform: scale(1);
        }
        to {
            transform: scale(2);
        }
    }

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    animation: scale 500ms infinite alternate;

    img {
        animation: spin 2000ms infinite linear;
        max-width: 70vw;
    }
`;

const MartijnButton = styled.button`
    background: #f7a41a;
    padding: 12px 24px;
    position: absolute;
    top: 20px;
    right: 10px;
    outline: none;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    font-family: 'Source Sans Pro', sans-serif;
    cursor: pointer;
    z-index: 99;
`;

const Header = styled.div`
    text-align: center;
    padding: 80px 0;
`;

const Main = styled.main`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 24px;
`;

const Col12 = styled.div`
    grid-column: span 12;
`;

const Col4 = styled.div`
    grid-column: span 12;

    @media (min-width: 1024px) {
        grid-column: span 4;
    }
`;
