import { IntlProvider } from 'react-intl';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Home from './Home';

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <IntlProvider locale="nl">
                <Home />
            </IntlProvider>
        </QueryClientProvider>
    );
}

export default App;
