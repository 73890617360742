import styled from '@emotion/styled';
import { FormattedNumber } from 'react-intl';

export const Coin = ({ name, investment, currentWorth, amount, rate, loading }) => {
    const difference = ((currentWorth - investment) / investment) * 100;

    console.log(amount);

    return (
        <Wrapper>
            <Top>
                <Logo>
                    <img src={`/images/${name}.png`} height="25" />
                    <Title>{name}</Title>
                </Logo>
                <Rate>
                    <FormattedNumber
                        value={rate}
                        style="currency"
                        currency="EUR"
                        maximumFractionDigits={8}
                    />
                </Rate>
            </Top>
            <Middle>
                <Description>Inleg</Description>
                <Value>
                    {loading ? (
                        'Laden...'
                    ) : (
                        <FormattedNumber value={investment} style="currency" currency="EUR" />
                    )}
                </Value>

                <Description>Coins</Description>
                <Value>{amount}</Value>

                <Description>Waarde</Description>
                <Value>
                    {loading ? (
                        'Laden...'
                    ) : (
                        <FormattedNumber value={currentWorth} style="currency" currency="EUR" />
                    )}
                </Value>
            </Middle>
            <Bottom isPositive={difference > 0}>
                {loading ? (
                    'Laden...'
                ) : (
                    <>
                        {difference > 0 && '+'}
                        {Math.round(difference * 100) / 100}%
                    </>
                )}
            </Bottom>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    border-radius: 10px;
    background: #ffffff;
`;

const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px 0;
`;

const Logo = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled.h3`
    font-size: 28px;
    color: rgb(2, 0, 36);
    margin-left: 12px;
`;

const Rate = styled.span`
    color: #888888;
    font-size: 12px;
`;

const Middle = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: #333333;
    padding: 18px 24px;
`;

const Description = styled.span`
    text-transform: uppercase;
    font-family: 'Roboto Mono', monospace;
    color: #888888;
    font-size: 18px;
`;

const Value = styled.span`
    text-align: right;
    font-size: 18px;
`;

const Bottom = styled.div`
    background: ${({ isPositive }) => (isPositive ? '#229554' : '#ee555a')};
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    color: #ffffff;
`;
